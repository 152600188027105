import Link from 'next/link';
import React from 'react';
import { Product } from '../../../models';
import { PLACEHOLDER } from '../../../config';

interface BestSellersSlideProps {
  product: Product;
  active: boolean;
  principal: boolean;
}

export default function BestSellersSlide({
  product,
  active,
  principal,
}: BestSellersSlideProps) {
  let display = '';
  if (active) {
    if (principal) {
      display = 'flex';
    } else {
      display = 'hidden sm:flex';
    }
  } else {
    display = 'hidden';
  }

  return (
    <div
      className={`h-60 w-44 md:h-96 md:w-72 p-5 overflow-hidden relative rounded-md ${display} justify-center flex-col items-center ${
        principal ? 'bg-primary-400' : ''
      }`}
      style={{
        boxShadow: `${principal ? '4px 4px 4px 2px rgba(0, 0, 0, 0.25)' : ''} `,
      }}
    >
      {/* Imagen */}
      <div className="h-3/4 overflow-hidden pb-5">
        <Link href="/product/[slug]" as={`/product/${product?._id}`} passHref>
          <img
            alt=""
            src={product?.photos[0] ?? PLACEHOLDER}
            className="h-full cursor-pointer"
          />
        </Link>
      </div>
      {/* Texto */}
      <div
        className={`w-full h-1/4 text-secondary-100 font-semibold ${
          principal ? 'block' : 'hidden'
        }`}
      >
        <Link href="/product/[slug]" as={`/product/${product?._id}`} passHref>
          <p
            className="h-full w-full flex items-center justify-center p-2 md:p-3 text-xs md:text-base bg-primary-500 cursor-pointer rounded-md overflow-hidden text-center"
            style={{
              boxShadow: `${
                principal ? '4px 4px 4px 2px rgba(0, 0, 0, 0.25)' : ''
              }`,
              background: 'rgba(51, 51, 51, 0.75)',
            }}
          >
            {product?.title}
          </p>
        </Link>
      </div>
    </div>
  );
}
