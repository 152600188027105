import React from 'react';
import { useQuery } from '@apollo/client';
import { HOME_PRODUCTS } from '../../graphql/queries';
import { Product, VariantValue } from '../../models';
import { Loader } from '..';
import HomeTitle from './HomeTitle';
import ProductCard from '../products/ProductCard';

interface HomeCatalogProps {
  locationId: string;
}

export default function HomeCatalog({ locationId }: HomeCatalogProps) {
  const { data, loading } = useQuery<{
    homeProducts: { items: { variantValue: VariantValue; product: Product }[] };
  }>(HOME_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        locationId,
      },
    },
  });

  return (
    <div className="h-auto w-full px-2 sm:px-16 flex flex-wrap justify-evenly">
      <HomeTitle title="Licores" />

      {loading ? (
        <div className="mb-16">
          <Loader />
        </div>
      ) : (
        <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-16">
          {data?.homeProducts?.items?.length &&
            data?.homeProducts?.items?.map((item) => (
              <ProductCard
                key={item?.variantValue?._id}
                {...item}
                className="mx-auto"
              />
            ))}
        </div>
      )}
    </div>
  );
}
