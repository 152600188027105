import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import { useSettings } from '../../hooks';

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 1,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 1000;
const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity;

interface EllipseProps {
  type: string;
}

const Ellipse = ({ type }: EllipseProps) => (
  <div
    className={`h-5 w-5 mx-2 rounded-full cursor-pointer ${
      type === 'dark' ? 'bg-primary-500' : 'bg-primary-300'
    }`}
  />
);

function Carousel() {
  const [[page, direction], setPage] = React.useState([0, 0]);
  const [settings] = useSettings();

  const imageIndex = wrap(0, settings?.carouselImages?.length, page);
  const paginate = (newDirection: number) => {
    setPage([newDirection, newDirection - page]);
  };

  React.useEffect(() => {
    const interval = setInterval(
      () => paginate((page + 1) % settings?.carouselImages?.length),
      15000
    );
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, direction]);

  return (
    <div className="h-hero w-full relative flex justify-center items-center overflow-hidden bg-gray-700">
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          alt=""
          src={settings?.carouselImages?.[imageIndex]?.url}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
          className="h-full w-full object-center object-cover"
        />
      </AnimatePresence>

      <div className="z-10 h-5 w-auto mb-2 sm:mb-7 absolute bottom-0">
        <div className="h-full w-full flex">
          {settings?.carouselImages?.map((img, index) => (
            <button
              type="button"
              aria-label="ellipse"
              key={index}
              onClick={(e) => {
                e.preventDefault();
                paginate(index);
              }}
            >
              <Ellipse type={index === page ? 'dark' : 'light'} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
