import React from 'react';
import Head from 'next/head';
import { useQuery } from '@apollo/client';
import { useLocationID } from '../hooks';
import Carousel from '../components/home/Carousel';
import HomeCatalog from '../components/home/HomeCatalog';
import BestSellersCarousel from '../components/home/bestSellers/BestSellersCarousel';
import HomeHero from '../components/home/HomeHero';
import { Product } from '../models';
import { BEST_SELLERS } from '../graphql/queries';
import Loader from '../components/shared/Loader';
import CartContextProvider from '../context/CartContext';

export default function HomePage() {
  const locationId = useLocationID();
  const { data, loading } = useQuery<{
    bestSellers: Product[];
  }>(BEST_SELLERS, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Head>
        <title>BodeBar</title>
      </Head>
      <Carousel />
      {/* Best Sellers */}
      {loading || !locationId ? (
        <div className="w-full py-20 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <BestSellersCarousel bestSellers={data?.bestSellers} />
          <HomeHero />
          <CartContextProvider>
            <HomeCatalog locationId={locationId} />
          </CartContextProvider>
        </>
      )}
    </>
  );
}
