import React from 'react';
import { Product } from '../../../models';
import Title from '../HomeTitle';
import BestSellersSlide from './BestSellerSlide';
import ArrowIcon from '../../icons/ArrowIcon';

interface BestSellersProps {
  bestSellers: Product[];
}

export default function BestSellersCarousel({ bestSellers }: BestSellersProps) {
  const [_bestSellers, setBestSellers] = React.useState<Product[]>(bestSellers);

  const moveSlide = (num: number) => {
    if (num === 1) {
      const auxBestSellers = [..._bestSellers];
      const product = auxBestSellers.pop();
      const finalList = [product].concat(auxBestSellers);

      setBestSellers(finalList);
    } else if (num === -1) {
      const auxBestSellers = [..._bestSellers];
      const productList = auxBestSellers.filter(
        (product, index) => index !== 0
      );
      const finalList = productList.concat(auxBestSellers[0]);

      setBestSellers(finalList);
    }
  };

  return (
    <div className="pb-24 px-8">
      <Title title="Best Sellers" />
      <div className="w-full flex justify-evenly items-center">
        <button
          type="button"
          aria-label="left"
          className="h-6 sm:h-8 focus:outline-none focus-within:ring-0"
          onClick={(e) => {
            e.preventDefault();
            moveSlide(-1);
          }}
        >
          <ArrowIcon direction="left" className="h-full" />
        </button>

        {_bestSellers
          ? _bestSellers.map((product, index) => (
              <BestSellersSlide
                key={index}
                product={product}
                active={index < 3}
                principal={index === 1}
              />
            ))
          : null}

        <button
          type="button"
          aria-label="right"
          className="h-6 sm:h-8 focus:outline-none focus-within:ring-0"
          onClick={(e) => {
            e.preventDefault();
            moveSlide(1);
          }}
        >
          <ArrowIcon direction="right" className="h-full" />
        </button>
      </div>
    </div>
  );
}
