import React from 'react';
import router from 'next/router';

export default function HomeHero() {
  return (
    <div
      className="overflow-hidden hidden md:flex md:items-center px-28 py-24"
      style={{
        background:
          'linear-gradient(0deg, rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)), url(/img/wine.jpg)',
        backgroundSize: '100% auto',
        backgroundPosition: 'center 65%',
        transform: 'scaleX(-1)',
      }}
    >
      <div
        className="z-10 w-2/5 font-bold text-center text-white flex justify-between flex-col items-center"
        style={{
          transform: 'scaleX(-1)',
        }}
      >
        <p className="italic text-4xl mb-10">Nuestras Promociones</p>

        <div className="text-xl italic">
          <p>No olvides revisar nuestras ofertas.</p>
          <p>No pierdas la oportunidad.</p>
          <p>Los mejores licores al mejor precio.</p>
        </div>

        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            localStorage.setItem('category', 'promotions');
            router.push('/products');
          }}
        >
          <p className="mt-5 px-4 py-2 font-semibold uppercase cursor-pointer bg-secondary-100 text-secondary-500 rounded-md">
            Ir a promociones
          </p>
        </button>
      </div>
    </div>
  );
}
